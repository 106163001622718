<template>
  <div class="product_box"
       @click="(e) => { $router.push('/product/detail?productId=' + myinfo.mallVipProductId + '&isVip=true') }"
  >
    <div class="pro_info flex-c-s-s">
      <van-image :style="{ opacity: myinfo.receiveFlag !== 1? '0.3' :'1' }" width="100%" height="100%"
                 :src="myinfo.iconUrl"
      />
      <div v-if="myinfo.receiveFlag === 4" class="pro_sale_out">
        <van-image width="2.29rem" height="0.69rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/del/pro_mask02.png"
        />
      </div>
      <div v-if="myinfo.receiveFlag === 3" class="pro_sale_no">
        <van-image width="2.29rem" height="0.69rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/del/pro_mask01.png"
        />
      </div>
      <div class="pro_name van-multi-ellipsis--l2" :style="{ opacity: myinfo.receiveFlag !== 1? '0.3' :'1' }">
        {{ myinfo.productName }}</div>
      <div class="pro_brand_model van-ellipsis" :style="{ opacity: myinfo.receiveFlag !== 1? '0.3' :'1' }">
        {{ myinfo.brand }} | {{ myinfo.specifications }}</div>
      <div class="pro_bottom ">
        <div v-if="myinfo.receiveFlag === 1" class="pro_vip_btn vip_can">{{ myinfo.price }}元领取</div>
        <div v-if="myinfo.receiveFlag === 2" class="pro_vip_btn vip_no">当前等级不足</div>
        <div v-if="myinfo.receiveFlag === 3" class="pro_vip_btn vip_no">{{ myinfo.price }}元领取</div>
        <div v-if="myinfo.receiveFlag === 4" class="pro_vip_btn vip_no">领完了</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: { type: Object, default: () => { } },
    coming: { type: String, default: 'list' }
  },
  data() {
    return {
      myinfo: this.info,
      mycoming: this.coming
    }
  },
  methods: {}
}
</script>
