<template>
  <div class="page_bg">
    <div class="bg_top_linear-gradient vip_linear-gradient">
      <!-- 顶部返回 -->
      <div class="back_top_name flex-r-sb-c">
        <van-icon name="arrow-left" @click="backPath" />
        <div class="top_name flex-r-c-c">
          <div>权益中心</div>
        </div>
        <div class="top_icon flex-r-c-c" style="font-size: 0.32rem;" @click="(e) => { $router.push('/user/vipAuth') }">
          <van-image style="margin-right:0.1rem" width="0.35rem" height="0.35rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/vip_tips.png"
          />
          <div>规则说明</div>
        </div>
      </div>
      <!-- vip 卡片切换 -->
      <swiper v-if="reloadSwiper" ref="carouselSwiper" class="service-advantage" :slides-per-view="1"
              :options="swiperOptions" @slideChange="slideChange"
      >
        <swiper-slide v-for="(item,index) in serviceAdvantageData" :key="`item${index}`"
                      :class="isVip ? 'slide bg'+ (index*1+1) :'slide sbg'+ (index*1+1) "
        >
          <div v-if="item.expirationTime"
               :class="isVip ? 'vip_left left_bg'+ (index*1+1):'vip_left left_sbg'+ (index*1+1) "
          > 当前等级 </div>
          <div v-if="item.status === 2"
               :class="isVip ? 'vip_left left_bg'+ (index*1+1):'vip_left left_sbg'+ (index*1+1) "
          > 待解锁 </div>
          <div class="vip_level">
            <van-image width="2.19rem" height="0.59rem"
                       :src="`https://hxxxkj-oss-cdn.xiaoyi120.com/sysx-xcx/vip/del_vip_icon0${index*1+1}.png`"
            />
          </div>
          <div
            :class=" isVip ? 'vip_info flex-r-sa-c text_color'+(index*1+1):'vip_info flex-r-sa-c stext_color'+(index*1+1)"
          >
            <div class="vip_info_msg">
              <div class="info_title">门店创收商品</div>
              <div class="info_title">已消费{{ item.monthRevenueConsume }}元</div>
              <div v-if="item.status === 2 && item.mallVipLevelId !== 1 && item.mallVipLevelId !== 101"
                   class="info_tips"
              >还需消费{{ item.needMoney3 }}元</div>
              <div v-if="item.status === 2 && item.mallVipLevelId !== 1 && item.mallVipLevelId !== 101"
                   class="info_tips"
              >次月升级{{ item.nextLevel }}享{{ item.nextLevelCount }}折</div>
            </div>
            <div class="vip_info_huo flex-c-c-c">
              <span>|</span><span>或</span><span>|</span>
            </div>
            <div class="vip_info_msg">
              <div class="info_title">贝思倍健</div>
              <div class="info_title">已消费{{ item.monthPetfectConsume }}元</div>
              <div v-if="item.status === 2 && item.mallVipLevelId !== 1 && item.mallVipLevelId !== 101"
                   class="info_tips"
              >还需消费{{ item.needMoney2 }}元</div>
              <div v-if="item.status === 2 && item.mallVipLevelId !== 1 && item.mallVipLevelId !== 101"
                   class="info_tips"
              >次月升级{{ item.nextLevel }}享{{ item.nextLevelCount }}折</div>
            </div>
          </div>
          <!-- <div v-if="item.status === 3" :class=" isVip ? ' text_color'+(index*1+1):' stext_color'+(index*1+1)">
            <div class="svip_info_over">{{ item.expirationTime }}到期</div>
            <div class="svip_info_money">累计已省{{ item.accumulatedSaved }}元</div>
          </div>
          <div v-if="item.status === 1">
            <div :class=" isVip ? 'vip_info_over text_color'+(index*1+1):'vip_info_over stext_color'+(index*1+1)">
              您已超越该等级</div>
          </div> -->
          <div :class="'normal text_color'+(index*1+1)">
            <div class="normal_pro">本月普通商品：已消费{{ item.monthConsume }}元</div>
            <div class="normal_bottom flex-r-sb-c">
              <div v-if="item.status === 3" class="normal_tips">当前等级权益将于{{ item.expirationTime }}到期</div>
              <div v-if="item.status === 1" class="normal_tips">您已超越该等级</div>
              <div v-if="item.status === 3" class="normal_money">累计已省{{ item.accumulatedSaved }}元</div>
              <div v-if="item.status === 2" class="normal_tips">
                还需消费{{ item.needMoney1 }}元，次月享{{ item.nextLevelCount }}折</div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <!-- tips -->
      <div class="vip_swiper_tip">
        <span>消费达到门槛,次月即可升级。左右滑动卡片,查看VIP规则</span>
      </div>
      <!-- 对应权益 -->
      <div class="vip_auth">
        <van-row type="flex" justify="space-around">
          <van-col v-for="(auth,auth_index) in vipAuthList" :key="auth_index" span="8" class="flex-c-c-c"
                   style="margin-top: 0.43rem;"
          >
            <van-image width="0.96rem" height="0.96rem"
                       :src="`https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/del/vip_icon_0${auth.icon}.png`"
            />
            <div v-if="auth.icon === '4' " class="vip_auth_title">
              {{ auth.title }}{{ nowDiscount !== 100 ? (nowDiscount/10 + '折') : '折扣' }}
            </div>
            <div v-else class="vip_auth_title">{{ auth.title }}</div>
            <div class="vip_auth_msg">{{ auth.msg }}</div>
          </van-col>
          <van-col v-for="(auth,auth_index) in 6 - vipAuthList.length" :key="auth_index" span="8" />
        </van-row>
      </div>
    </div>
    <!-- 可兑换商品列表  -->
    <div class="vip_pro_box" :style="{marginTop: parseInt(nowDiscount) === 100 ? '-4.5rem':'-2rem'}">
      <div class="vip_pro_top flex-r-s-c">
        <van-image width="0.79rem" height="0.69rem" style="margin-top: 0.1rem;"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/del/vip_pro_logo.png"
        />
        <div>
          <span class="vip_pro_level">{{ limitIinfo.mallVipLevelName }}会员专享</span>
          <span class="vip_pro_num">当月剩余可领{{ limitIinfo.surplusNum }}次</span>
        </div>
      </div>
      <div class="vip_pro_list" name="list">
        <MyscrollList ref="myList01" :loading="listLoading" :list="proList" @myload="proload">
          <template #default="slotProps">
            <proCardVip :info="slotProps.item" coming="cart" @updata="updataCart" />
          </template>
        </MyscrollList>
      </div>
    </div>

    <!-- 滑动提示 -->
    <van-dialog v-model="scrollDialog" :show-confirm-button="false" style="background: none;">
      <div class="scrollDialog_box flex-c-c-c">
        <van-image width="4.59rem" height="1.87rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/scrollDialog.png"
        />
        <div class="scrollDialog_tips">左右滑动卡片，查看不同VIP规则</div>
        <div class="scrollDialog_btn" @click="scrollDialog = false">知道了</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import './vipDel.scss'
import { mallVipProduct_list, memberCenter, mallVipLimit_detail, vipSlide } from '@/api/vip'
import { vipAuth1, vipAuth, svipAuth } from '@/utils/constants'
import MyscrollList from '@/components/MyscrollList'
import proCardVip from '@/components/pro_card_vip'
export default {
  name: 'VipDetail',
  components: { MyscrollList, proCardVip },
  data() {
    return {
      reloadSwiper: false,
      vipAuthList: vipAuth,
      isVip: true,
      myVipLevel: null,
      serviceAdvantageData: [],
      nowVipLevel: {},
      swiperIndex: 0,
      nowDiscount: 100,
      swiperOptions: {
        initialSlide: 0,
        slidesPerView: 1.25, // 设置slider容器能够同时显示的slides数量,可以是小数,设置为2时,如图所示，设置为3则会出现三张完整的active slide，如API的例子,即设置为偶数时会自动使两边的缩进，类似遮盖一半的效果
        spaceBetween: -20,
        centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左
        loop: false,
        slideToClickedSlide: true, // true:点击slide会过渡到这个slide,默认false
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      limitIinfo: {},
      // 商品列表
      proSearch: { pageNum: 1, pageSize: 20 },
      proList: [],
      listLoading: false,
      scrollDialog: false
    }
  },
  created() {
    this.getProList()
    this.getVipInfo()
    this.scrollTip()
  },
  methods: {
    // 滑动提醒
    scrollTip() { vipSlide().then(res => { this.scrollDialog = res.data }) },
    backPath() { window.history.back() },
    slideChange(e) {
      this.swiperIndex = this.$refs.carouselSwiper.$swiper.activeIndex
      console.log('下标', this.swiperIndex)
      this.nowDiscount = this.serviceAdvantageData[this.swiperIndex].discount
      if (this.nowDiscount === 100) {
        this.vipAuthList = vipAuth1
      } else {
        this.vipAuthList = this.myVipLevel < 99 ? vipAuth : svipAuth
      }
    },
    // 获取vip信息
    getVipInfo() {
      memberCenter().then(res => {
        for (var j = 0; j < res.data.length; j++) {
          if (res.data[j].expirationTime) {
            console.log('j', j)
            this.swiperOptions.initialSlide = j > 4 ? j - 4 : j
            console.log('this.swiperOptions.initialSlide', this.swiperOptions.initialSlide)
            this.swiperIndex = j
            this.myVipLevel = res.data[j].mallVipLevelId
            this.nowDiscount = res.data[j].discount
            this.$set(res.data[j], 'status', 3)
            // 计算当前等级 的下一个等级的需求
            if (res.data[(j + 1)]) {
              this.$set(res.data[j], 'needMoney1',
                (res.data[(j + 1)].start - res.data[j].monthConsume) * 1 <= 0 ? 0 : (res.data[(j + 1)].start - res.data[j].monthConsume).toFixed(2))
              this.$set(res.data[j], 'needMoney2',
                (res.data[(j + 1)].petfectStart - res.data[j].monthPetfectConsume) * 1 <= 0 ? 0 : (res.data[(j + 1)].petfectStart - res.data[j].monthPetfectConsume).toFixed(2))
              this.$set(res.data[j], 'needMoney3',
                (res.data[(j + 1)].revenueStart - res.data[j].monthRevenueConsume) * 1 <= 0 ? 0 : (res.data[(j + 1)].revenueStart - res.data[j].monthRevenueConsume).toFixed(2))
              this.$set(res.data[j], 'nextLevel', res.data[(j + 1)].mallVipLevelName)
              this.$set(res.data[j], 'nextLevelCount', res.data[(j + 1)].discount)
            }
          }
        }
        this.$nextTick(() => {
          var start = this.myVipLevel < 99 ? 0 : 5
          var lengthMax = this.myVipLevel < 99 ? 5 : 8
          for (var i = start; i < lengthMax; i++) {
            // status  1:已超过 2:未达到 3:当前
            if (res.data[i].mallVipLevelId < this.myVipLevel) { this.$set(res.data[i], 'status', 1) }
            if (res.data[i].mallVipLevelId > this.myVipLevel) { this.$set(res.data[i], 'status', 2) }
            if (res.data[i].expirationTime === null) {
              this.$set(res.data[i], 'needMoney1',
                (res.data[i].start - res.data[i].monthConsume) * 1 <= 0 ? 0 : (res.data[i].start - res.data[i].monthConsume).toFixed(2))
              this.$set(res.data[i], 'needMoney2',
                (res.data[i].petfectStart - res.data[i].monthPetfectConsume) * 1 <= 0 ? 0 : (res.data[i].petfectStart - res.data[i].monthPetfectConsume).toFixed(2))
              this.$set(res.data[i], 'needMoney3',
                (res.data[i].revenueStart - res.data[i].monthRevenueConsume) * 1 <= 0 ? 0 : (res.data[i].revenueStart - res.data[i].monthRevenueConsume).toFixed(2))
              this.$set(res.data[i], 'nextLevel', res.data[i].mallVipLevelName)
              this.$set(res.data[i], 'nextLevelCount', res.data[i].discount)
            }
            this.isVip = i < 5
            this.vipAuthList = i < 5 ? vipAuth : svipAuth
            if (parseInt(this.myVipLevel) === 1) { this.vipAuthList = vipAuth1 }
            this.serviceAdvantageData.push(res.data[i])
          }
          this.reloadSwiper = true
        })
      })
      mallVipLimit_detail().then(res => {
        this.limitIinfo = res.data
      })
    },
    // 推荐商品
    getProList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      mallVipProduct_list(this.proSearch).then(res => {
        this.$toast.clear()
        this.$refs.myList01.load_loading(false)
        this.proList = this.proList.concat(res.data.data)
        if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
          if (res.data.data.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
        }
      })
    },
    proload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch.pageNum = this.proSearch.pageNum + 1
        this.getProList()
        // 加载状态结束
      }, 50)()
    },
    // 加入购物车 更新 当前视图
    updataCart() {
      this.reload = false
      this.getCartList()
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper-slide {
  height: 5.62rem !important;
  transition: 300ms;
  transform: scale(0.8);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}

.slide {
  position: relative;
  width: 100%;
  height: 6.03rem !important;

  &:nth-child(2n) {
    width: 80%;
  }
  &:nth-child(3n) {
    width: 20%;
  }
}
.scrollDialog_tips {
  font-size: 0.37rem;
  color: #fff;
  margin-top: 0.2rem;
}
.scrollDialog_btn {
  width: 4.11rem;
  height: 1.07rem;
  line-height: 1.07rem;
  text-align: center;
  border-radius: 0.53rem;
  background: #fff;
  border: 0.03rem solid #f5f5f5;

  font-size: 0.48rem;
  color: #0d0e15;

  margin-top: 1rem;
}
</style>
